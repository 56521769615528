export const environment = {
  production: false,
  API_BASE_URL: 'https://dev-api-soflex.mindfactory.ar/api',
  DNI_ID: 4,
  TipoArchivosFichaMedica: {
    CERTIFICADO_DISCAPACIDAD: 7,
    CERTIFICADO_DE_ALERGIAS: 1090,
    CERTIFICADO_DE_GRUPO_SANGUINEO: 1091
  },
  TipoArchivoDatosFamiliares: {
    PARTIDA_NACIMIENTO: 1039,
    PARTIDA_NACIMIENTO_AFINIDAD: 1040,
    CERTIFICADO_DISCAPACIDAD: 22,
  },
  TipoAscenso: {
    ALTA: 7,
    BAJA_ALTA_DEC286: 8,
    ALTA_PM: 12,
    ALTA_PFA: 11,
    ASCENSO: 2,
    ASCENSO_DEC_103_21: 10,
    ASCENSO_ORDINARIO: 1011,
    ASCENSO_EXTRAORDINARIO_SERV: 1013,
    ASCENSO_ART_227: 1014,
    ASCENSO_EXTRAORDINARIO_Y_ART_227: 1015,
    REINCORPORACION_JUDICIAL: 14,
  },
  TipoEstadoTramiteDesvinculacion: {
    RETIRO_VOLUNTARIO: 1,
    JUBILACION: 3,
    RENUNCIA: 4,
  },
  SituacionDeRevista: {
    PASIVA: 139,
    SERVICIO_EFECTIVO: 143,
    DISPONIBILIDAD: 138,
    Hijos: {
      EFECTIVO: 150,
      LIC_ESPECIAL: 176,
      LIC_SIN_GOCE_HABERES: 177,
      LIC_POR_CARGO_SUPERIOR: 178,
      SUMARIADO: 157,
      DETENIDO_DELITO_CULPOSO_VINCULADO: 159,
      SIN_DESTINO: 155,
    },
  },
  SituacionAdministrativa: {
    PERSONAL_EN_COMISION_EXTERNA: 4,
  },
  RelacionEmpleo: {
    ACTIVIDAD: 1,
  },
  TipoDePersonal: {
    POLICIAL: 9,
    CIVIL: 12,
  },
  AmbitoDestino: {
    MINISTERIAL: 1,
  },

  Nacionalidad: {
    Argentina: 1,
  },

  TipoArchivoJuntaPermanenteCalificaciones: {
    ACTA: 1082,
    NOTIFICACION: 1083,
  },
  TipoArchivoBeneplacitos: {
    RESOLUCION: 1084,
    ODI: 1085,
    NOTIFICACION: 1086,
  },
  TipoArchivoLegajos: {
    PFA_PRIMERA_PARTE: 1088,
    PFA_SEGUNDA_PARTE: 1089,
    PM_PDC_CBDAB: 1087,
  },
};
