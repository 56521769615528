import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected readonly hostAddress = environment.API_BASE_URL;
  private _httpClient = inject(HttpClient);

  public getHostAddress(): string {
    return this.hostAddress;
  }

  public get<T>(route: string, params?: any): Observable<T> {
    const queryParams = params ? this.buildParamsFromObject(params) : new HttpParams();
    return this.getPiped(
      this._httpClient.get<T>(`${this.hostAddress}/${route}`, {
        params: queryParams,
      })
    );
  }

  public getFile(route: string, params?: any): Observable<Blob> {
    const queryParams = params ? this.buildParamsFromObject(params) : new HttpParams();
    return this._httpClient
      .get(`${this.hostAddress}/${route}`, {
        params: queryParams,
        responseType: 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  public put<TSend, TResult>(route: string, payload: TSend, params?: HttpParams): Observable<TResult> {
    return this.getPiped(
      this._httpClient.put<TResult>(`${this.hostAddress}/${route}`, payload, {
        params,
      })
    );
  }

  public post<TSend, TResult>(route: string, payload?: TSend, params?: HttpParams, headers?: HttpHeaders): Observable<TResult> {
    return this.getPiped(
      this._httpClient.post<TResult>(`${this.hostAddress}/${route}`, payload, {
        params,
        headers
      })
    );
  }

  public patch<TSend, TResult>(route: string, payload?: TSend, params?: HttpParams): Observable<TResult> {
    return this.getPiped(
      this._httpClient.patch<TResult>(`${this.hostAddress}/${route}`, payload, {
        params,
      })
    );
  }

  public delete<TResult, TBody = {}>(route: string, params?: HttpParams, body?: TBody): Observable<TResult> {
    return this.getPiped(
      this._httpClient.delete<TResult>(`${this.hostAddress}/${route}`, {
        body: body,
        params
      })
    );
  }

  private buildParamsFromObject(obj: any): HttpParams {
    let params = new HttpParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value !== null && value !== undefined) {
          if (Array.isArray(value)) {
            for (const item of value) {
              if (item !== null && item !== undefined) {
                params = params.append(key, this.formatValue(item));
              }
            }
          } else {
            params = params.append(key, this.formatValue(value));
          }
        }
      }
    }
    return params;
  }

  private formatValue(value: any): string {
    if (value instanceof Date) {
      return this.formatDate(value);
    }
    return value.toString();
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private getPiped<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(
      tap((response: any) => {
        /*if (response.messages.length !== 0){
              if (response.succeeded){
                this.toast.open(response.messages[0], 'Acción exitosa!');
              } else {
                this.toast.open(response.messages[0], 'Acción fallida!');
              }
            }*/
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public postFile<TSend>(route: string, payload?: TSend, params?: any, headers?: HttpHeaders): Observable<Blob> {
    const queryParams = params ? this.buildParamsFromObject(params) : new HttpParams();
    return this._httpClient
      .post(`${this.hostAddress}/${route}`, payload, {
        params: queryParams,
        headers,
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        })
      );
  }

}
